.contactPage {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  /* Ensure the homepage container takes full width */
}

.contact-form-container {
  max-width: 80vw;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  /* Adjust the background as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Sleek shadow for depth */
  border-radius: 8px;
  /* Rounded corners */
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-form-container label {
  margin-top: 10px;
}

.contact-form-container input,
.contact-form-container select,
.contact-form-container textarea {
  padding: 10px;
  width: auto;
  margin-top: 5px;
  border: 1px solid #ccc;
  /* Light grey border */
  border-radius: 4px;
  /* Rounded corners for input fields */
}

.contact-form-container button {
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #333;
  /* Dark background for the button */
  color: white;
  /* White text color */
  border: none;
  border-radius: 4px;
  /* Rounded corners for the button */
  cursor: pointer;
}

.contact-form-container button:hover {
  background-color: #555;
  /* Slightly lighter on hover */
}

/* If you're using a <button> or <a> tag for "Click Me", style it here */
.contact-button {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  /* Choose an appropriate font size */
  cursor: pointer;
}

.click-me-links {
  display: flex;
  margin: 10px;
  grid-gap: 30px;
  font-size: large;
}

.click-me-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Center items horizontally */
  border-radius: 20px;
  background-color: #c4a4a4;
  /* Instagram brand color */
  padding: 10px 10vw;
  /* Adjust padding for better proportions */
  width: auto;
  /* Let the width be determined by the content size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* subtle shadow for depth */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
  text-decoration: none;
  /* Remove underline if it's a link */
  color: white;
  /* Text color */
  font-weight: bold;
  /* Make the text bold */
  cursor: pointer;
}

.click-me-links a:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Larger shadow on hover */
  transform: translateY(-2px);
  /* Slight lift effect on hover */
}

.click-me-links img {
  width: 40px;
  /* Fixed width for the icon */
  height: auto;
  /* Maintain aspect ratio */
  margin-left: 10px;
  /* Space between the icon and text */
}

a.contact-email {
  background-color: #0072C6 !important;
}

form.loading {
  pointer-events: none; /* Disable all interactions */
  opacity: 0.5; /* Optional: make the form slightly transparent */
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  z-index: 10004; /* Ensure it's on top of the form */
}

.AboutSimmiContact{
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}
