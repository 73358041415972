.mehndiPage {
    margin: 0;
    padding: 0;
    width: 100vw;
    align-items: center;
    /* Ensure the homepage container takes full width */
}

@media (min-width: 800px) {
.aboutSimmiMehndi{
        width: 80%;
        
    }
}

.aboutSimmiMehndi{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 10px;
    overflow: hidden;
}