.logo-nav-bar-container {
  width: 100vw;
  /* Ensure this is 100vw to cover full viewport width */
  height: 100vh;
  overflow: hidden;
  top: 0;
  position: sticky;
  opacity: 1;
  background-color: #c4a4a4;
  filter: drop-shadow(0 10px 40px rgba(0, 0, 0, 0.5));
  z-index: 1000;
}

.centered-logo-home {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
}

.centered-logo-home, .logo-nav-bar-container, .shrinkContainer{
  transition: 1.5s ease-in-out;
}
/* Animation for the logo */
.centered-logo-home.shrink-logo {
  transform: translate(-50%, -53%) scale(0.45);
  z-index: 80;
}

.social-media-links {
  position: absolute;
  /* Ensure it's in the normal document flow */
  z-index: 70;
  /* Higher z-index to be on top of other elements */
  padding-top: 15px;
  opacity: 0.65;
}

.shrinkContainer {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 24vh;
  z-index: 1000;
}

.social-media-links img {
  margin: 5px 15px;
  /* Add some space between icons */
  filter: drop-shadow(0 0px 10px rgba(255, 255, 255, 0.5));
}

.social-media-links a {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Inherit color from parent */
}

.background-image-home{
  top: 0;
  position: static;
  width: 100vw;
  opacity: 0.05;
}

@media (max-width: 767px) {
  .centered-logo-home.shrink-logo {
    position: absolute;
    transform: translate(-50%, -52.5%) scale(0.25);
  }
}