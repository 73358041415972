
.simmi-profile-image {
  width: 30%;
  filter: drop-shadow(0 0px 2rem rgba(0, 0, 0, 0.6));
  border-radius: 1rem;
}

.landingOreintation{
  align-items: center;
  text-align: center;
}

/* Media query for phones */
@media (max-width: 1099px) {
  .simmi-profile-image {
    width: 40% !important;
    margin: 1rem;
  }
  .simmiParagraph p{
    margin: 0 2rem 2rem 2rem !important;
  }
  .landingOreintation{
    margin: 1rem;
  }
}

@media (min-width: 1100px) {
  .landingBox{
    width: 90% !important;
  }
  .landingOreintation{
    display: flex;
    flex-direction: row;
  }
  .simmiParagraph {
    margin: 0.5rem 0 4rem 0;
  }
  .simmiParagraph p{
    padding: 0.5rem 4rem 0 4rem;
  }
  .simmi-profile-image{
    margin: 1.25rem 0 4rem 3rem;
  }
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* Centers content vertically in the container */
  align-items: center;
}

.landingBox{
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: 10px;
  text-align: center;
  align-items: center;
}
.simmiParagraph p{
  font-size: large;
}
.simmiParagraph{
  align-items: center;
  text-align: center;
}

.brand-icons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}

.brand-icon {
  width: auto; /* Adjust size as needed */
  height: 3rem;
}