/* Style for the navigation bar */
.navigation {
  z-index: 80;
  position: absolute; /* Changed to fixed to stay at the bottom of the screen */
  bottom: 0; /* Adjust as necessary based on header size */
  left: 0;
  width: 100%;
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Center the items vertically */
}

.navigation ul {
  display: flex;
  justify-content: center; /* Horizontally centers the list items */
  align-items: center; /* Vertically centers the list items */
  width: 100%; /* Ensures the ul takes the full width of its parent */
  padding: 0;
  list-style: none;
}

/* Style for each list item */
.navigation li {
  margin: 0 20px; /* Add horizontal margin for spacing between items */
  border: none; /* Remove the border */
  display: inline-block; /* Align items in line */
  background-color: rgba(57, 53, 49, 0.25); /* Button background */
  border-radius: 50px; /* Pill shape */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions for shadow and raise */
  transition: background-color 0.7s ease-out;
}

/* Style for navigation links */
.navigation a {
  white-space: nowrap;
  text-decoration: none;
  color: rgba(255, 255, 255, 1); /* Change as needed */
  display: block; /* To make the entire area clickable */
  padding: 10px 50px; /* Adjust padding for spacing */
  border-radius: 50px; /* Pill shape */
}

/* Hover and focus styles */
.navigation li:hover, .navigation li:focus-within {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Drop shadow effect */
  transform: translateY(-5px); /* Raise effect */
}

/* Media query for when the header expands */
@media (max-width: 767px) {
  .navigation a {
    padding: 5px 10px; /* Adjust padding as necessary */
  }
  .navigation li {
    margin: 0px 5px;
  }
}

.navigation li.active {
  background-color: rgba(57, 53, 49, 0.8); /* Full opacity */
  transform: translateY(-5px); /* Raise effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Drop shadow effect */
}
