.grid {
  column-count: 5;
  column-gap: 10px;
  margin: 10px;
}

.grid-item {
  break-inside: avoid;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 20px;
}

.grid-item:hover {
  z-index: 1;
  transform: scale(1.03);
  filter: drop-shadow(0 0px 40px rgba(0, 0, 0, 1));
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.slider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider img {
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 800px;
  margin-top: 10px;
}

.hover-area {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5em;
  color: white;
  transition: background-color 0.3s ease;
}

.hover-left, .hover-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-right {
  left: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.hover-left {
  left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.hover-left:hover, .hover-right:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .grid {
    column-count: 3;
  }

  .navigation-buttons {
    width: 100%;
    justify-content: space-around;
  }
}
