
.makeupPage {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100vw;
    /* Ensure the homepage container takes full width */
}

@media (min-width: 800px) {
.aboutSimmiMakeup{
    width: 80%;
}
}
.aboutSimmiMakeup{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 10px;
    overflow: hidden;
}